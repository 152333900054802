import { useEffect } from "react";
import Aos from "aos";
import Marquee from "react-fast-marquee";
import ImageTitle1 from "./assets/images/img-title-1.png";
import IconSocial1 from "./assets/images/icon-1.png";
import IconSocial2 from "./assets/images/icon-2.png";
import IconSocial3 from "./assets/images/icon-3.png";
import ImageIntro from "./assets/images/img-intro.png";
import ImageBox from "./assets/images/img-box.png";
import ImagePanda1 from "./assets/images/img-panda-1.png";
import ImagePanda2 from "./assets/images/img-panda-2.png";
import ImagePanda3 from "./assets/images/img-panda-3.png";
import ImagePanda4 from "./assets/images/img-panda-4.png";
import ImageCard1 from "./assets/images/img-card-1.png";
import ImageCard2 from "./assets/images/img-card-2.png";
import ImageCard3 from "./assets/images/img-card-3.png";
import ImageCard4 from "./assets/images/img-card-4.png";
import ImageCard5 from "./assets/images/img-card-5.png";
import ImageCard6 from "./assets/images/img-card-6.png";
import "./App.scss";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    Aos.init({
      duration: 1500,
    });
  }, []);
  return (
    <div className="App">
      <header className="page-header">
        <div className="container wrapper">
          <ul className="socials">
            <li>
              <a
                href="https://mevx.io/solana/HkNdUDPinSVvyB2AwV5keHfyt9Vs9rVx3BEhS8Dpump?ref=volumealert"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>Chart</p>
                <img src={IconSocial1} alt="" />
              </a>
            </li>
            <li>
              <a
                href="https://t.me/PanduSolana"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>Telegram</p>
                <img src={IconSocial2} alt="" />
              </a>
            </li>
            <li>
              <a
                href="https://x.com/PanduSolana"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>Twitter</p>
                <img src={IconSocial3} alt="" />
              </a>
            </li>
          </ul>
        </div>
      </header>
      <main className="page-main">
        <section className="section-intro">
          <div data-aos="fade-up" className="container wrapper">
            <div className="img-title">
              <img src={ImageTitle1} alt="" />
            </div>
            <p className="address">
              HkNdUDPinSVvyB2AwV5keHfyt9Vs9rVx3BEhS8Dpump
            </p>
            <div className="img-intro">
              <img src={ImageIntro} alt="" />
            </div>
          </div>
        </section>
        <section className="section-about">
          <Marquee>
            <div className="marquee">
              {new Array(10).fill("$PANDU").map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>
          </Marquee>
          <div data-aos="fade-up" className="container wrapper">
            <div className="img-left">
              <img src={ImagePanda1} alt="" />
            </div>
            <div className="img-right">
              <img src={ImagePanda2} alt="" />
            </div>
            <div className="box-content">
              <img src={ImageBox} alt="" />
              <div className="content">
                <p className="title">ABOUT $PANDU</p>
                <p className="des">
                  Deep within the bamboo grove, $PANDU emerges—an emblem of
                  quiet strength, balance, and unwavering resolve, inspired by
                  the panda's spirit. Built on unity and shared goals, it adapts
                  and flourishes with every new ally. As the community expands,
                  so does its collective power, grounded in harmony yet driven
                  by determination. Carry the essence of $PANDU. Become part of
                  a natural force.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="section-card">
          <Marquee>
            <div className="marquee">
              {new Array(10).fill("$PANDU").map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>
          </Marquee>
          <div data-aos="fade-up" className="container wrapper">
            <ul className="list-card">
              {[
                ImageCard1,
                ImageCard2,
                ImageCard3,
                ImageCard4,
                ImageCard5,
                ImageCard6,
              ].map((item, index) => (
                <li key={index}>
                  <img src={item} alt="" />
                </li>
              ))}
            </ul>
          </div>
        </section>
        <section className="section-community">
          <div data-aos="fade-up" className="container wrapper">
            <div className="img-left">
              <img src={ImagePanda3} alt="" />
            </div>
            <div className="img-right">
              <img src={ImagePanda4} alt="" />
            </div>
            <div className="img-title">
              <img src={ImageTitle1} alt="" />
            </div>
            <p className="des">Join The Community Today!</p>
            <ul className="socials">
              <li>
                <a
                  href="https://t.me/PanduSolana"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p>Telegram</p>
                  <img src={IconSocial2} alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://x.com/PanduSolana"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p>Twitter</p>
                  <img src={IconSocial3} alt="" />
                </a>
              </li>
            </ul>
          </div>
        </section>
      </main>
      <footer className="page-footer">
        <Marquee>
          <div className="marquee">
            {new Array(10).fill("$PANDU").map((item, index) => (
              <p key={index}>{item}</p>
            ))}
          </div>
        </Marquee>
      </footer>
    </div>
  );
}

export default App;
